<script setup>
    import Layout from "@/layouts/main.vue";
    import PageHeader from "@/components/page-header";  
    import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
    import { required, helpers } from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import { computed, ref, reactive } from 'vue';
    import { useStore } from 'vuex';

    const state = reactive({
        name: '',
        process: 'auto',
        folder: null,
        file_setting_id: 1
    });

    const rules = {
        name: {
            required: helpers.withMessage("Name is required", required),
        },
        process: {
            required: helpers.withMessage("Process is required", required),
        },
        folder: {
            required: helpers.withMessage("Folder is required", required),
        },
        file_setting_id: {
            required: helpers.withMessage("File Setting is required", required),
        }
    }

    const store = useStore();
    const v$ = useVuelidate(rules, state);

    const title = ref("Camera Type");
    const items = ref([
        {
            text: "Home",
            href: "/",
        },
        {
            text: "Camera",
            href: "/camera/type/list",
            active: true
        },
        {
            text: "Add New Camera Type",
            active: true,
        }
    ]);

    const submitted = ref(false);
    const loading = computed(() => store.state.globals.loading);
    const notification = computed(() => store ? store.state.notification : null)

    const createCameraType = () => {
        submitted.value = true
        v$.value.$touch();

        if (v$.value.$invalid) return;
        store.dispatch('camera/createCameraType', state);
    }
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col lg-12">
            <b-overlay :show="loading">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title mb-0">Add New Camera Type</h4>
                    </div>
                    <div class="card-body">
                        <div v-if="notification && notification.messages.length > 0">
                            <b-alert v-for="(message, index) in notification.messages" :key="index"
                                show variant="danger" class="fade show" role="alert">
                                {{ message }}
                            </b-alert>
                        </div>

                        <form @submit.prevent="createCameraType" class="mx-2">
                            <div class="row">
                                <p class="fs-6 text text-decoration-underline">Camera Type Information</p>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="name" class="form-label required">Name</label> 
                                        <input 
                                            autofocus
                                            :class="{ 'is-invalid': submitted && v$.name.$error }"
                                            v-model="state.name" class="form-control" type="text" placeholder="Enter name..." id="name" />
                                        <div
                                            v-for="item of v$.name.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="process" class="form-label required">Process</label> 
                                        <select
                                            v-model="state.process"
                                            :class="{ 'is-invalid': submitted && v$.process.$error }"
                                            class="form-select"
                                            id="process"
                                        >
                                            <option value="auto">Auto</option>
                                            <option value="manual">Manual</option>
                                        </select>
                                        <div
                                            v-for="item of v$.process.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="folder" class="form-label required">Folder</label> 
                                        <input
                                            type="text"
                                            v-maska="'/XXXXXXXXXXXXXXXXXXXX'"
                                            :class="{ 'is-invalid': submitted && v$.folder.$error }"
                                            v-model="state.folder" class="form-control" placeholder="Enter folder..." id="folder" />
                                        <div class="text-muted">e.g /path-to-folder</div>
                                        <div
                                            v-for="item of v$.folder.$errors"
                                            :key="item.$uid"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">{{ item.$message }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="file-setting" class="form-label required">File Setting</label> 
                                        <select id="file-setting" v-model="state.file_setting_id" class="form-select">
                                            <option value="1" selected>s3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-end">
                                        <router-link to="/camera/type/list" type="submit" class="btn btn-danger mr-3">
                                            <i class="ri-close-fill fs-4 mr-1 valign-middle"></i>
                                            Cancel
                                        </router-link>
                                        <button type="submit" class="btn btn-success">
                                            <i class="ri-save-2-fill fs-4 mr-1 valign-middle"></i>
                                            Add Camera Type
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
  </Layout>
</template>